import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Lead from '../components/Lead';
import Button from '../components/Button';
import HeroImage from '../components/HeroImage';
import Section from '../components/Section';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';
import Slider from '../components/Slider';

import styles from './projects.module.scss';

const ProjectsPage = ({ data, location: { pathname, state } }) => {
  const {
    markdownRemark: {
      frontmatter: { meta, projects, lead, heroImage, cta, testimonials },
    },
  } = data;

  return (
    <Layout className={styles.root} pathname={pathname} meta={meta} state={state}>
      <HeroImage imageInfo={heroImage.image} alt="TODO">
        <div className={styles.heroImageContent}>
          <h1 className="title">{heroImage.title}</h1>
          {heroImage.button ? (
            <Button inverted to={heroImage.button.url}>
              {heroImage.button.text}
            </Button>
          ) : null}
        </div>
      </HeroImage>
      <Lead {...lead} />
      <section className="section">
        <div className="container">
          {projects.map(({ title, body, image }) => (
            <div key={title} className={styles.project}>
              <div className="content" dangerouslySetInnerHTML={{ __html: body }} />
              <div className={styles.imageWrapper}>
                <h3 className="title">{title}</h3>
                <PreviewCompatibleImage alt={title} imageInfo={image} />
              </div>
            </div>
          ))}
        </div>
      </section>
      {testimonials && testimonials.length ? (
        <section className="section">
          <div className={`container ${styles.testimonials}`}>
            <Slider>
              {testimonials.map(({ name, body }) => (
                <article key={name}>
                  <h3 className="title">{name}</h3>
                  <cite className="content" dangerouslySetInnerHTML={{ __html: body }} />
                </article>
              ))}
            </Slider>
          </div>
        </section>
      ) : null}
      {cta && cta.body ? <Section {...cta} /> : null}
    </Layout>
  );
};

ProjectsPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        meta: PropTypes.shape({
          title: PropTypes.string,
          description: PropTypes.string,
        }).isRequired,
        heroImage: PropTypes.shape({
          title: PropTypes.string,
          image: PropTypes.object,
          button: PropTypes.shape({
            text: PropTypes.string,
            url: PropTypes.string,
          }),
        }).isRequired,
        lead: PropTypes.shape({
          body: PropTypes.string,
          button: PropTypes.shape({
            text: PropTypes.string,
            url: PropTypes.string,
          }),
        }).isRequired,
        cta: PropTypes.shape({
          body: PropTypes.string,
          button: PropTypes.shape({
            text: PropTypes.string,
            url: PropTypes.string,
          }),
        }),
        projects: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
            body: PropTypes.string,
            image: PropTypes.object,
          })
        ),
        testimonials: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            body: PropTypes.string,
          })
        ),
      }),
    }),
  }),
};

export const projectsPageQuery = graphql`
  query projectsPage {
    markdownRemark(frontmatter: { templateKey: { eq: "projects-page" } }) {
      frontmatter {
        meta {
          title
          description
        }
        heroImage {
          image {
            childImageSharp {
              fluid(maxWidth: 1280, quality: 95) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          button {
            text
            url
          }
          title
        }
        lead {
          body
          button {
            text
            url
          }
        }
        cta {
          body
          button {
            text
            url
          }
        }
        projects {
          title
          body
          image {
            childImageSharp {
              fluid(maxWidth: 640, quality: 95) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        #testimonials {
        #  name
        #  body
        #}
      }
    }
  }
`;

export default ProjectsPage;
