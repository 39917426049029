import React from 'react';
import PropTypes from 'prop-types';

import styles from './Slider.module.scss';

class Slider extends React.Component {
  state = { position: 0 };

  componentDidMount() {
    this.itv = setInterval(this.slide, this.props.slideDuration * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.itv);
  }

  slide = () => {
    let position = this.state.position - 100;
    if (position <= this.props.children.length * -100) {
      position = 0;
    }
    this.setState({ position });
  };

  render() {
    const { children } = this.props;
    return (
      <div className={styles.root}>
        {children.map((child, i) => (
          <div key={`slide_${i}`} style={{ transform: `translateX(${this.state.position}%)` }}>
            {child}
          </div>
        ))}
      </div>
    );
  }
}

Slider.propTypes = {
  children: PropTypes.node.isRequired,
  slideDuration: PropTypes.number.isRequired,
};

Slider.defaultProps = {
  slideDuration: 3,
};

export default Slider;
